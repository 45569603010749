import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
  xIconFacebook,
  xIconInstagram,
  xIconPinterest,
  xIconTiktok,
  xIconTwitter,
  xIconYouTube,
} from '@x/common/icon';
import { IFooterLinkCategoriesContent, IShopNavigationItemContent } from '@x/content/client';
import { CONTENT_DATA, DynamicContentComponent } from '@x/content/render';

export type IShopNavigationItemContent_Icon = Omit<IShopNavigationItemContent, 'icon'> & {
  icon?: IconDefinition | null;
};

interface IFooterLinkCategory {
  label?: string;
  categoryLinks: IShopNavigationItemContent_Icon[];
}

function setIconByClass(iconClass: string) {
  const iconMap: { [key: string]: IconDefinition } = {
    facebook: xIconFacebook,
    twitter: xIconTwitter,
    instagram: xIconInstagram,
    pinterest: xIconPinterest,
    youtube: xIconYouTube,
    tiktok: xIconTiktok,
  };

  return iconMap[iconClass] || undefined;
}

function mapIShopNavigationItemContent_Icon(links: IShopNavigationItemContent[]) {
  return links.map((link) => {
    return {
      ...link,
      icon: setIconByClass(link.icon ?? '') ?? undefined,
    };
  });
}

@Component({
  selector: 'x-footer-link-list',
  templateUrl: './footer-link-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLinkListComponent
  implements DynamicContentComponent<IFooterLinkCategoriesContent>
{
  @Input()
  set content(content: IFooterLinkCategoriesContent) {
    this.linkCategories = [
      {
        label: content.labelOne ?? undefined,
        categoryLinks: mapIShopNavigationItemContent_Icon(content.linkCategoryOne ?? []),
      },
      {
        label: content.labelTwo ?? undefined,
        categoryLinks: mapIShopNavigationItemContent_Icon(content.linkCategoryTwo ?? []),
      },
      {
        label: content.labelThree ?? undefined,
        categoryLinks: mapIShopNavigationItemContent_Icon(content.linkCategoryThree ?? []),
      },
      {
        label: content.labelFour ?? undefined,
        categoryLinks: mapIShopNavigationItemContent_Icon(content.linkCategoryFour ?? []),
      },
    ];
  }

  linkCategories: IFooterLinkCategory[] = [];

  constructor(@Inject(CONTENT_DATA) @Optional() content: IFooterLinkCategoriesContent) {
    if (content) this.content = content;
  }
}
