<a [href]="url" target="_blank" *ngIf="isAlternativeLink(); else ELSE">
  <ng-container *ngTemplateOutlet="CONTENT_BUTTON"></ng-container>
</a>

<ng-template #ELSE>
  <a [xLink]="url" [target]="openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="CONTENT_BUTTON"></ng-container>
  </a>
</ng-template>

<ng-template #CONTENT_BUTTON>
  <div class="{{ this.appearance }} color-{{ this.color }} align-{{ this.alignment }}">
    {{ text }}
  </div>
</ng-template>
