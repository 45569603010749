import { CommonTransformationOptions } from '@x/common/cloudinary';

/*
 * https://cloudinary.com/documentation/video_player_api_reference
 * */
export interface CloudinaryPlayerConfig {
  cloudName: string;
  autoplay?: boolean | 'muted';
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  preload?: 'none' | 'metadata' | 'auto';
  sourceTypes?: string[];
  fluid?: boolean;
  fill?: boolean;
  width?: number;
  height?: number;
  showLogo?: boolean;
  hideContextMenu?: boolean;
  transformation?: CommonTransformationOptions;
  sourceTransformation?: Record<string, CommonTransformationOptions>;
  posterOptions?: {
    transformation?: CommonTransformationOptions;
    posterColor?: string;
  };
}

export type TVideoMode = 'Autoplay_With_Controls' | 'Autoplay_No_Controls' | 'Manual_Play';

export const VIDEO_MODE_OPTIONS: Record<TVideoMode, Omit<CloudinaryPlayerConfig, 'cloudName'>> = {
  Autoplay_With_Controls: {
    autoplay: true,
    controls: true,
    muted: true,
  },
  Autoplay_No_Controls: {
    autoplay: true,
    controls: false,
    muted: true,
  },
  Manual_Play: {
    autoplay: false,
    controls: true,
    muted: false,
  },
};
