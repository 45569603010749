import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { TMediaResponsiveConfig } from '@x/common/media-thumbnail/media-thumbnail.config';
import { IActionGridContent, IActionGridItemContent } from '@x/content/client';
import { CONTENT_DATA, ThemeableComponent } from '@x/content/render';
import { ACTION_GRID_MEDIA_CONFIG } from '@x/content/render/dynamic-components/action-grid/action-grid';
import { ContentMediaService } from '@x/content/render/services/content-media.service';

@Component({
  selector: 'x-action-grid, [x-action-grid]',
  templateUrl: './action-grid.component.html',
  host: { class: 'x-action-grid' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionGridComponent extends ThemeableComponent {
  @Input()
  set content(content: IActionGridContent) {
    this.title = content.title;
    this.description = content.description;
    this.background = content.background;
    this.items = content.actionGridItems ?? [];
    this.displayControl = content.displayControl;
    this.responsiveMediaConfig =
      this.contentMediaService.getResponsiveMedia(ACTION_GRID_MEDIA_CONFIG)['action-grid'];
  }

  title?: string | null;
  description?: string | null;
  items: IActionGridItemContent[] = [];
  responsiveMediaConfig: TMediaResponsiveConfig;

  constructor(
    private contentMediaService: ContentMediaService,
    @Inject(CONTENT_DATA) @Optional() content: IActionGridContent,
  ) {
    super();
    if (content) this.content = content;
  }
}
