<div
  class="container-fluid mat-typography text-center d-flex flex-column justify-content-center align-items-center"
  [ngClass]="cardGridType"
>
  <h2 *ngIf="title" class="mb-4">{{ title }}</h2>
  <div class="card-container">
    <ng-container *ngFor="let item of items">
      <ng-template #card>
        <div class="card-grid-item align-self-stretch">
          <x-content-media [media]="item.image" [height]="100" [width]="100"></x-content-media>
          <x-markdown-view
            *ngIf="item?.description"
            [source]="item?.description ?? ''"
          ></x-markdown-view>
        </div>
      </ng-template>

      <ng-container *ngIf="item?.url; else noLink">
        <a [routerLink]="item?.url?.urlWithoutFragment" [fragment]="item?.url?.fragment ?? ''">
          <ng-container *ngTemplateOutlet="card"></ng-container>
        </a>
      </ng-container>

      <ng-template #noLink>
        <ng-container *ngTemplateOutlet="card"></ng-container>
      </ng-template>
    </ng-container>
  </div>
</div>
