import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivationEnd, Router, UrlSerializer } from '@angular/router';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DynamicDialogComponentFactoryService } from './dynamic-dialog-component-factory.service';

/**
 * Dialog reference/link
 * dialog://DialogTypename?arg1=true
 */
export type DialogRef = string;

@Injectable({ providedIn: 'root' })
export class ContentDialogService {
  defaultDialogOptions: MatDialogConfig = {
    maxWidth: '1000px',
    width: '100%',
    hasBackdrop: true,
    autoFocus: false,
  };

  routedDialogRef: MatDialogRef<any> | null = null;

  constructor(
    private matDialog: MatDialog,
    private dialogFactory: DynamicDialogComponentFactoryService,
    private urlSerializer: UrlSerializer,
    private router: Router,
  ) {}

  isDialogRef(dref: any): dref is DialogRef {
    return typeof dref === 'string' && new URL(dref).protocol === 'dialog:';
  }

  captureRouterFragmentEvents() {
    return this.router.events.pipe(
      map((event) => {
        if (event instanceof ActivationEnd) {
          if (this.routedDialogRef) {
            this.routedDialogRef.close();
            this.routedDialogRef = null;
          }

          const fragment = event.snapshot.fragment;

          if (fragment && this.isDialogRef(fragment)) {
            return this.launchDialogRef(fragment);
          }
        }
        return null;
      }),
      filter((ref): ref is Promise<MatDialogRef<any>> => {
        return !!ref;
      }),
      switchMap((ref) => {
        return from(ref);
      }),
    );
  }

  async launchDialogRef(dref: DialogRef): Promise<MatDialogRef<any, any>> {
    const url = new URL(dref);
    const typename = url.pathname.replace('//', '');

    const args: Record<string, any> = {};

    url.search
      .replace('?', '')
      .split('&')
      .map((p) => p.split('='))
      .reduce((args, [key, value]) => {
        args[key] = value;
        return args;
      }, args);

    console.info('ContentDialogService: launching dialog for reference %s', dref, args);

    return this.launchDialog(typename, args);
  }

  async launchDialog(typename: string, args?: any): Promise<MatDialogRef<any, any>> {
    const dialog = await this.dialogFactory.make(typename, args);

    return this.matDialog.open(dialog.type, dialog.config);
  }
}
