import { SectionWithImageHeight, SectionWithImageType } from '@x/content/client';
import { TContentMediaConfig } from '@x/content/render/types/content-media.config';

export const SECTION_WITH_IMAGE_CONFIG: TContentMediaConfig<
  SectionWithImageType,
  SectionWithImageHeight
> = {
  'sidebar-narrow': {
    '800/640': ['XS', 'SM'],
    '1400/424': ['LG'],
    '1600/484': ['XL', 'XXL'],
  },
  'sidebar-half': {
    '800/640': ['XS', 'SM'],
    '1400/683': ['MD', 'LG'],
    '1600/780': ['XL', 'XXL'],
  },
  'sidebar-full': {
    '800/640': ['XS', 'SM'],
    '1400/933': ['MD', 'LG'],
    '1600/1067': ['XL', 'XXL'],
  },
  'half-narrow': {
    '800/640': ['XS', 'SM'],
    '800/363': ['MD'],
    '1000/454': ['LG', 'XL', 'XXL'],
  },
  'half-half': {
    '600/600': ['XS', 'SM'],
    '1000/600': ['MD', 'LG', 'XL', 'XXL'],
  },
  'half-full': {
    '600/600': ['XS', 'SM'],
    '700/700': ['MD'],
    '1000/1000': ['LG', 'XL', 'XXL'],
  },
};
