<div
  class="py-4 px-3"
  [class.container-fluid]="this.layout.container === 'fluid'"
  [class.container]="this.layout.container === 'contain'"
>
  <div class="d-flex align-items-center mat-typography justify-content-center">
    <div class="text-center">
      <h2 *ngIf="title">{{ title }}</h2>
      <x-markdown-view *ngIf="description" [source]="description"></x-markdown-view>
    </div>
  </div>

  <div class="list d-flex flex-row" [ngClass]="{ 'items-center': items.length < 4 }">
    <div class="action-list-item" *ngFor="let action of items">
      <a
        [xLink]="action?.url"
        *ngIf="action?.media"
        [attr.aria-label]="action?.media?.alternativeText ?? title ?? ''"
      >
        <x-content-media
          [media]="action.media"
          [responsiveMediaConfig]="responsiveMediaConfig"
        ></x-content-media>
      </a>
      <x-markdown-view *ngIf="action.description" [source]="action.description"></x-markdown-view>

      <section class="button-section">
        <x-button [content]="{ url: action?.url ?? '', text: action?.text ?? '' }"></x-button>
      </section>
    </div>
  </div>
</div>
