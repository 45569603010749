import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { isAlternativeLink } from '@x/common/utils';
import { ButtonAlignment, ButtonAppearance, ButtonColor, IButtonContent } from '@x/content/client';

@Component({
  selector: 'x-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  host: {
    class: 'x-button',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ButtonComponent implements OnInit {
  @Input()
  set content(content: Partial<IButtonContent>) {
    this.text = content?.text;
    this.openInNewTab = content?.openInNewTab ?? false;
    this.url = content?.url;
    this.color = content?.color ?? 'default';
    this.alignment = content?.alignment ?? 'start';
    this.appearance = content?.appearance ?? 'link';
  }

  @Input()
  text?: string | null;

  @Input() url?: string | null;

  @Input()
  @HostBinding('class')
  appearance?: ButtonAppearance | null = 'link';

  @Input()
  @HostBinding('class')
  alignment?: ButtonAlignment | null = 'start';

  @Input()
  @HostBinding('class')
  color?: ButtonColor | null = 'default';

  @Input()
  openInNewTab: boolean;

  constructor() {}

  ngOnInit(): void {}

  isAlternativeLink() {
    return isAlternativeLink(this.url ?? '');
  }
}
