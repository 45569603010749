import { createNgModuleRef, Inject, Injectable, Injector, Type } from '@angular/core';
import {
  DYNAMIC_DIALOG_MODULE_MAP,
  DynamicDialogConfig,
  DynamicDialogModuleMap,
  DynamicDialogModuleType,
} from '@x/content/render';

@Injectable()
export class DynamicDialogComponentFactoryService {
  constructor(
    private injector: Injector,
    @Inject(DYNAMIC_DIALOG_MODULE_MAP)
    private moduleMap: DynamicDialogModuleMap,
  ) {}

  public async make(
    typename: string,
    args?: any,
  ): Promise<{ config: DynamicDialogConfig; type: Type<any> }> {
    const module = await this.loadModule(typename);
    const moduleRef = createNgModuleRef(module, this.injector);

    const type = moduleRef.instance.getDialogType();
    const config = moduleRef.instance.getDialogConfig(args);
    return {
      config,
      type,
    };
  }

  public async loadModule(typename: string): Promise<DynamicDialogModuleType> {
    const modulePromise = this.moduleMap[typename];

    if (!modulePromise) {
      throw new Error(
        `DynamicContentComponentFactoryService: Dynamic dialog module '${typename}', not registered.`,
      );
    }

    const module = await modulePromise();
    return module;
  }
}
