import { DynamicDialogModuleMap } from './types/dynamic-dialog-component.type';

export const CORE_DYNAMIC_DIALOGS: DynamicDialogModuleMap = {
  accomodation: () =>
    import(
      './dynamic-dialogs/accomodation-booking-dialog/accommodation-booking-dialog.module'
    ).then((m) => m.AccommodationBookingDialogModule),
  acuity: () =>
    import('./dynamic-dialogs/acuity-booking-dialog/acuity-booking-dialog.module').then(
      (m) => m.AcuityBookingDialogModule,
    ),
  resdiary: () =>
    import('./dynamic-dialogs/resdiary-booking-dialog/resdiary-booking-dialog.module').then(
      (m) => m.AcuityBookingDialogModule,
    ),
};
