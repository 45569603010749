import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken, Type } from '@angular/core';

export const CONTENT_DATA = new InjectionToken<any>('CONTENT_DATA');
export const DYNAMIC_CONTENT_COMPONENT_MAP = new InjectionToken<DynamicContentComponentMap>(
  'DYNAMIC_CONTENT_COMPONENT_MAP',
);

export type DynamicContentModuleType = Type<DynamicComponentModule<DynamicContentComponent<any>>>;

export type DynamicContentComponentMap = Record<
  string,
  () => Promise<DynamicContentModuleType> | DynamicContentModuleType
>;

export type DynamicContentData = {
  __typename: string;
} & Record<string, any>;

export interface DynamicContentComponent<T extends DynamicContentData> {
  set content(content: T);
}

export interface DynamicComponentModule<T extends DynamicContentComponent<any>> {
  getDynamicComponentType(): ComponentType<T>;
}

export interface IMediaSize {
  mediaWidth: number;
  mediaHeight: number;
}
