import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentRenderSharedModule } from '../../content-render-shared.module';
import { DynamicComponentModule } from '../../types/content-component.type';
import { AnchorComponent } from './anchor.component';

@NgModule({
  imports: [ContentRenderSharedModule, CommonModule],
  exports: [AnchorComponent],
  declarations: [AnchorComponent],
  providers: [],
})
export class AnchorModule implements DynamicComponentModule<AnchorComponent> {
  getDynamicComponentType(): ComponentType<AnchorComponent> {
    return AnchorComponent;
  }
}
