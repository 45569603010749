import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import {
  CarouselLayout,
  CarouselType,
  ICarouselContent,
  ICarouselItemContent,
  IThemeLayout,
} from '@x/content/client';
import { CONTENT_DATA, ThemeableComponent } from '@x/content/render';
import {
  CAROUSEL_LAYOUT_BREAKPOINTS,
  CAROUSEL_OPTIONS_MAP,
} from '@x/content/render/dynamic-components/carousel/carousel';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'x-carousel, [x-carousel]',
  templateUrl: './carousel.component.html',
  host: { class: 'x-carousel mat-typography' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class CarouselHolderComponent extends ThemeableComponent {
  @Input()
  set content(content: ICarouselContent) {
    this.title = content.title ?? null;
    this.description = content.description ?? null;
    this.carouselType = content.carouselType;
    this.carouselLayout = content.carouselLayout ?? 'full';
    this.items = content.carouselItems;
    this.layout = content.layout ?? this.layout;
    this.displayControl = content.displayControl;
  }

  title: string | null = null;
  description: string | null = null;
  items: ICarouselItemContent[] = [];

  @HostBinding('class')
  _carouselType: CarouselType = 'default';
  set carouselType(carouselType: CarouselType) {
    this._carouselType = carouselType;
    this.carouselOptions = CAROUSEL_OPTIONS_MAP[carouselType];

    switch (carouselType) {
      default:
      case 'default':
        this.mediaWidth = 1200;
        this.mediaHeight = 800;
        break;
      case 'tickertape':
        this.mediaWidth = 400;
        this.mediaHeight = 600;
        break;
      case 'banner':
        this.mediaWidth = 800;
        this.mediaHeight = 800;
        break;
    }
  }
  get carouselType() {
    return this._carouselType;
  }

  private _carouselLayout: CarouselLayout = 'full';
  set carouselLayout(carouselLayout) {
    this._carouselLayout = carouselLayout;
    this.carouselOptions = CAROUSEL_LAYOUT_BREAKPOINTS[this.carouselLayout];
  }
  get carouselLayout() {
    return this._carouselLayout;
  }

  private _layout: IThemeLayout = {
    container: 'fluid',
    textPositionHorizontal: 'start',
    textPositionVertical: 'center',
    textAlignment: 'center',
  };
  set layout(layout: IThemeLayout) {
    if (!layout) return;

    const { container, textPositionVertical, textPositionHorizontal, textAlignment } = layout;

    this._layout = {
      ...this._layout,
      ...(container && { container }),
      ...(textPositionVertical && { textPositionVertical }),
      ...(textPositionHorizontal && { textPositionHorizontal }),
      ...(textAlignment && { textAlignment }),
    };
  }
  get layout() {
    return this._layout;
  }

  private _carouselOptions: SwiperOptions = CAROUSEL_OPTIONS_MAP.default;
  set carouselOptions(options: SwiperOptions) {
    this._carouselOptions = { ...this._carouselOptions, ...options };
  }
  get carouselOptions() {
    return this._carouselOptions;
  }

  mediaWidth = 1200;
  mediaHeight = 800;

  constructor(@Inject(CONTENT_DATA) @Optional() content: ICarouselContent) {
    super();
    if (content) this.content = content;
  }

  onBreakpoint(swiperOptions: SwiperOptions) {
    // const { slidesPerView, loop } = swiperOptions;
    //
    // if (typeof slidesPerView !== 'number' || !loop) return;
    //
    // let updatedOptions = { ...swiperOptions };
    //
    // if (slidesPerView >= this.items.length) {
    //   updatedOptions.loop = false;
    //   updatedOptions.autoplay = false;
    // } else {
    //   updatedOptions = {
    //     ...TICKER_TAPE_LOOP_OPTIONS,
    //   };
    // }
    //
    // this.carouselOptions = updatedOptions;
    //
    // console.log(this.carouselOptions);
  }
}
