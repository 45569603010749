import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatSelectModule } from '@angular/material/select';
import { LinkModule } from '@x/common/link';
import { BrandIconComponent } from './components/brand-icon/brand-icon.component';
import { ButtonComponent } from './components/button/button.component';
import { DynamicComponentDirective } from './directives/dynamic-component.directive';
import { MediaTypePipe } from './pipes/media-type.pipe';

@NgModule({
  imports: [CommonModule, MatLuxonDateModule, MatSelectModule, LinkModule],
  declarations: [BrandIconComponent, ButtonComponent, MediaTypePipe, DynamicComponentDirective],
  exports: [BrandIconComponent, ButtonComponent, MediaTypePipe, DynamicComponentDirective],
})
export class ContentRenderSharedModule {}
