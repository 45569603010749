import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { IButtonContent, ISectionThreeContent, IThemeLayout } from '@x/content/client';
import { CONTENT_DATA, ThemeableComponent } from '@x/content/render';

@Component({
  selector: 'x-section-three, [x-section-three]',
  templateUrl: './section-three.component.html',
  host: { class: 'x-section' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class SectionThreeComponent extends ThemeableComponent {
  @Input()
  set content(content: ISectionThreeContent) {
    this.title = content?.title;
    this.descriptionOne = content?.descriptionOne;
    this.descriptionTwo = content?.descriptionTwo;
    this.descriptionThree = content?.descriptionThree;
    this.background = content.background;

    this.buttonOne = content?.buttonOne ?? null;
    this.buttonTwo = content?.buttonTwo ?? null;
    this.buttonThree = content?.buttonThree ?? null;

    this.layout = content.layout ?? this.layout;
    this.displayControl = content.displayControl;
  }

  title?: string | null;

  descriptionOne?: string | null;

  buttonOne: IButtonContent[] | null;
  buttonTwo: IButtonContent[] | null;
  buttonThree: IButtonContent[] | null;

  descriptionTwo?: string | null;

  descriptionThree?: string | null;

  buttons: IButtonContent[];
  set button(val: IButtonContent) {
    this.buttons = [val];
  }

  layout: IThemeLayout = {
    container: 'fluid',
    textPositionHorizontal: 'start',
    textPositionVertical: 'center',
    textAlignment: 'center',
  };

  constructor(
    @Inject(CONTENT_DATA)
    @Optional()
    content: ISectionThreeContent,
  ) {
    super();
    if (content) this.content = content;
  }
}
