<div [class.container]="this.layout.container === 'contain'" class="g-0">
  <div class="d-lg-flex justify-content-center layout-pos-{{ this.textPosition }}">
    <div
      class="left-col media"
      [ngClass]="this.sectionWithImageType === 'half' ? 'col-lg-6' : 'col-lg-9'"
    >
      @if (secondaryMediaForMobile) {
        <!--desktop use main media-->
        <x-content-media
          class="visible-lg-up"
          [media]="mainMedia"
          [responsiveMediaConfig]="responsiveMediaConfig"
          [mode]="videoConfig?.mode"
        ></x-content-media>

        <!--secondary media (image only) for mobile-->
        <x-content-media
          class="visible-lg-down"
          [media]="secondaryMediaForMobile"
          [responsiveMediaConfig]="responsiveMediaConfig"
          [mode]="videoConfig?.mode"
        ></x-content-media>
      } @else {
        <x-content-media
          [media]="mainMedia"
          [responsiveMediaConfig]="responsiveMediaConfig"
          [mode]="videoConfig?.mode"
        ></x-content-media>
      }
    </div>

    <div
      class="d-flex align-items-center"
      [ngClass]="this.sectionWithImageType === 'half' ? 'col-lg-6' : 'col-lg-3'"
      [class]="'justify-content-' + this.layout.textPositionHorizontal"
    >
      <div
        class="content d-flex flex-column p-5"
        [class]="'align-items-' + this.layout.textAlignment"
      >
        <h2 *ngIf="title" [class]="'text-' + this.layout.textAlignment">{{ title }}</h2>
        <x-markdown-view
          *ngIf="description"
          [source]="description"
          [class]="'text-' + this.layout.textAlignment"
        ></x-markdown-view>
        <section class="button-section">
          <div class="buttons mt-3">
            <div class="button" *ngFor="let button of buttons">
              <x-button [content]="button"></x-button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
