import { Component, HostBinding, Input } from '@angular/core';
import {
  AnyBrochureComponentContent,
  AnyShopComponentContent,
  IDisplayControlContent,
  IThemeBackground,
  ThemeBackgroundColor,
} from '@x/content/client';

@Component({ template: '' })
export class ThemeableComponent {
  backgroundColor: ThemeBackgroundColor = 'transparent';
  displayClass = 'component-show';

  @Input()
  set background(background: IThemeBackground | null | undefined) {
    if (!background?.color) return;

    this.backgroundColor = `${background.color}`;
  }

  @Input()
  set displayControl(control: IDisplayControlContent | null | undefined) {
    if (!control?.showOnDevices) return;

    this.displayClass = this.updateDisplayClasses(control.showOnDevices);
  }

  @HostBinding('class')
  get combine() {
    return `background-${this.backgroundColor} ${this.displayClass}`;
  }

  private updateDisplayClasses(devices: string[]) {
    if (devices.length === 3) return 'component-show';

    const classList = ['component-hide'];

    if (devices.includes('Small')) {
      classList.push('component-small');
    }
    if (devices.includes('Medium')) {
      classList.push('component-medium');
    }
    if (devices.includes('Large')) {
      classList.push('component-large');
    }

    return classList.join(' ');
  }

  public set content(value: AnyBrochureComponentContent | AnyShopComponentContent) {}
}
