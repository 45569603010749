<div class="section three mat-typography py-md-5 background-{{ this.backgroundColor }}">
  <div class="container text-center">
    <h2 *ngIf="title">{{ title }}</h2>
    <div class="row">
      <div class="col-12 col-md-4 column-one px-3">
        <x-markdown-view *ngIf="descriptionOne" [source]="descriptionOne"></x-markdown-view>
        <section class="button-section align-{{ this.layout.textAlignment }}">
          <div class="buttons">
            <div class="button" *ngFor="let button of buttonOne">
              <x-button [content]="button"></x-button>
            </div>
          </div>
        </section>
      </div>
      <div class="col-12 col-md-4 column-two px-3 mid-column">
        <x-markdown-view *ngIf="descriptionTwo" [source]="descriptionTwo"></x-markdown-view>
        <section class="button-section align-{{ this.layout.textAlignment }}">
          <div class="buttons">
            <div class="button" *ngFor="let button of buttonTwo">
              <x-button [content]="button"></x-button>
            </div>
          </div>
        </section>
      </div>
      <div class="col-12 col-md-4 column-three px-3">
        <x-markdown-view *ngIf="descriptionThree" [source]="descriptionThree"></x-markdown-view>
        <div>
          <section class="button-section align-{{ this.layout.textAlignment }}">
            <div class="buttons">
              <div class="button" *ngFor="let button of buttonThree">
                <x-button [content]="button"></x-button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
