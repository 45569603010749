import { Injectable } from '@angular/core';
import { IMediaSize, IMediaSource } from '@x/common/media-thumbnail/media-thumbnail.config';
import { Breakpoints, DeviceSizeMediaQueries, DeviceSizes } from '@x/common/responsive';
import {
  TContentMediaConfig,
  TResponsiveContentMedia,
} from '@x/content/render/types/content-media.config';

@Injectable({
  providedIn: 'root',
})
export class ContentMediaService {
  getResponsiveMedia<Mode1 extends string, Mode2 extends string>(
    config: TContentMediaConfig<Mode1, Mode2>,
  ): TResponsiveContentMedia<Mode1, Mode2> {
    return Object.entries(config).reduce<TResponsiveContentMedia<Mode1, Mode2>>(
      (acc, [sizeMode, aspectRatios]) => {
        acc[sizeMode as keyof TResponsiveContentMedia<Mode1, Mode2>] = Object.keys(
          Breakpoints,
        ).reduce<Record<DeviceSizes, IMediaSource>>(
          (innerAcc, size) => {
            innerAcc[size as DeviceSizes] = { width: 0, height: 0, mediaQuery: '' };
            return innerAcc;
          },
          {} as Record<DeviceSizes, IMediaSource>,
        );

        Object.entries(aspectRatios as Record<string, DeviceSizes[]>).forEach(
          ([aspectRatio, deviceSizes]) => {
            deviceSizes.forEach((size) => {
              const mediaQuery = DeviceSizeMediaQueries[size];
              // const {height, width} = this.mediaSizeFromAspectRatio(aspectRatio);

              acc[sizeMode as keyof TResponsiveContentMedia<Mode1, Mode2>][size] = {
                ...this.mediaSizeFromAspectRatio(aspectRatio),
                mediaQuery,
              };
            });
          },
        );

        return acc;
      },
      {} as TResponsiveContentMedia<Mode1, Mode2>,
    );
  }

  private mediaSizeFromAspectRatio(aspectRatio: string): IMediaSize {
    const [width, height] = aspectRatio.split('/').map(Number);
    return { width, height };
  }
}
