import { InjectionToken } from '@angular/core';
import { DynamicContentComponentMap } from './types/content-component.type';

export const CONTENT_RENDER_BRAND_CONFIG = new InjectionToken<ContentRenderBrandConfig>(
  'CONTENT_RENDER_BRAND_CONFIG',
);

export interface ContentRenderBrandConfig {
  name: string;
  shortName: string;
  brandAppId: string;
  logo: {
    svgPath: string;
    svgViewBox: string;
  };
  emblem: {
    svgPath: string;
    svgViewBox: string;
  };
}

export interface ContentRenderModuleConfig {
  brand: ContentRenderBrandConfig;
  components?: DynamicContentComponentMap;
}
