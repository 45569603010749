import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CloudinaryModule } from '@x/common/cloudinary';
import { LinkModule } from '@x/common/link/link.module';
import { MarkdownModule } from '@x/common/markdown';
import { SwiperComponent } from '@x/common/swiper/swiper.component';
import { DynamicComponentModule } from '@x/content/render';
import { ContentMediaComponent } from '@x/content/render/components/content-media/content-media.component';
import { ContentRenderSharedModule } from '@x/content/render/content-render-shared.module';
import { CarouselHolderComponent } from '@x/content/render/dynamic-components/carousel/carousel.component';

@NgModule({
  declarations: [CarouselHolderComponent],
  imports: [
    CloudinaryModule,
    CommonModule,
    MarkdownModule,
    LinkModule,
    MatButtonModule,
    ContentRenderSharedModule,
    ContentMediaComponent,
    SwiperComponent,
  ],
  exports: [CarouselHolderComponent],
})
export class CarouselModule implements DynamicComponentModule<CarouselHolderComponent> {
  getDynamicComponentType(): ComponentType<CarouselHolderComponent> {
    return CarouselHolderComponent;
  }
}
