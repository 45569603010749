import { Directive, Injector, Input, OnInit, ViewContainerRef } from '@angular/core';
import { DynamicContentComponentFactoryService, DynamicContentData } from '@x/content/render';

@Directive({
  selector: '[xDynamicComponent]',
})
export class DynamicComponentDirective implements OnInit {
  @Input('xDynamicComponent')
  content: DynamicContentData;

  constructor(
    private injector: Injector,
    private viewContainer: ViewContainerRef,
    private componentFactory: DynamicContentComponentFactoryService,
  ) {}

  ngOnInit(): void {
    if (!this.content) {
      throw new Error('DynamicComponentDirective: content not provided');
    }
    if (!this.content.__typename) {
      throw new Error('DynamicComponentDirective: content __typename not provided');
    }
    this.componentFactory
      .createComponentInViewContainer(this.viewContainer, this.content, this.injector)
      .catch((err) =>
        console.warn(
          'DynamicComponentDirective: Error creating dynamic component for content: %o',
          this.content,
          err,
        ),
      );
  }
}
