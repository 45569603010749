import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { CloudinaryModule } from '@x/common/cloudinary';
import { LinkModule } from '@x/common/link/link.module';
import { MarkdownModule } from '@x/common/markdown';
import { DynamicComponentModule } from '../../types/content-component.type';
import { FaqComponent } from './faq.component';

@NgModule({
  declarations: [FaqComponent],
  imports: [CloudinaryModule, CommonModule, MarkdownModule, LinkModule, MatExpansionModule],
  exports: [FaqComponent],
})
export class FaqModule implements DynamicComponentModule<FaqComponent> {
  getDynamicComponentType(): ComponentType<FaqComponent> {
    return FaqComponent;
  }
}
