import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloudinaryModule } from '@x/common/cloudinary';
import { LinkModule } from '@x/common/link/link.module';
import { MarkdownModule } from '@x/common/markdown';
import { ContentMediaComponent } from '@x/content/render/components/content-media/content-media.component';
import { ContentRenderSharedModule } from '../../content-render-shared.module';
import { DynamicComponentModule } from '../../types/content-component.type';
import { ActionGridComponent } from './action-grid.component';

@NgModule({
  declarations: [ActionGridComponent],
  imports: [
    CloudinaryModule,
    CommonModule,
    MarkdownModule,
    LinkModule,
    ContentRenderSharedModule,
    ContentMediaComponent,
  ],
  exports: [ActionGridComponent],
})
export class ActionGridModule implements DynamicComponentModule<ActionGridComponent> {
  getDynamicComponentType(): ComponentType<ActionGridComponent> {
    return ActionGridComponent;
  }
}
