import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { IButtonContent, ISectionOneContent, IThemeLayout } from '@x/content/client';
import { CONTENT_DATA, ThemeableComponent } from '@x/content/render';

@Component({
  selector: 'x-section-one, [x-section-one]',
  templateUrl: './section-one.component.html',
  host: { class: 'x-section' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class SectionOneComponent extends ThemeableComponent {
  set content(content: ISectionOneContent) {
    this.title = content?.title;
    this.description = content?.description;
    this.buttons = content?.buttons ?? null;
    this.background = content.background;
    this.layout = content.layout ?? this.layout;
    this.displayControl = content.displayControl;
  }

  title?: string | null;

  description?: string | null;

  buttons: IButtonContent[] | null;
  set button(val: IButtonContent) {
    this.buttons = [val];
  }

  layout: IThemeLayout = {
    container: 'fluid',
    textPositionHorizontal: 'start',
    textPositionVertical: 'center',
    textAlignment: 'center',
  };

  constructor(
    @Inject(CONTENT_DATA)
    @Optional()
    content: ISectionOneContent,
  ) {
    super();
    if (content) this.content = content;
  }
}
