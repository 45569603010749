<h2 *ngIf="title">{{ title }}</h2>
<p *ngIf="description">{{ description }}</p>
<mat-accordion>
  <mat-expansion-panel *ngFor="let panel of expansionPanels" [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ panel.title }}</mat-panel-title>
    </mat-expansion-panel-header>
    <x-markdown-view class="mt-3" *ngIf="panel.text" [source]="panel.text"></x-markdown-view>
  </mat-expansion-panel>
</mat-accordion>
