import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloudinaryModule } from '@x/common/cloudinary';
import { LinkModule } from '@x/common/link/link.module';
import { MarkdownModule } from '@x/common/markdown';
import { MediaModule } from '@x/common/media';
import { ResponsiveModule } from '@x/common/responsive';
import { DynamicComponentModule } from '@x/content/render';
import { ContentMediaComponent } from '@x/content/render/components/content-media/content-media.component';
import { ContentRenderSharedModule } from '../../content-render-shared.module';
import { SectionWithImageComponent } from './section-with-image.component';

@NgModule({
  declarations: [SectionWithImageComponent],
  imports: [
    CloudinaryModule,
    CommonModule,
    MarkdownModule,
    LinkModule,
    ContentRenderSharedModule,
    ResponsiveModule,
    MediaModule,
    ContentMediaComponent,
  ],
  exports: [SectionWithImageComponent],
})
export class SectionWithImageModule implements DynamicComponentModule<SectionWithImageComponent> {
  getDynamicComponentType(): ComponentType<SectionWithImageComponent> {
    return SectionWithImageComponent;
  }
}
