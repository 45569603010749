<section
  class="p-3"
  [class.container]="layout.container === 'contain'"
  [class.pe-0]="layout.container === 'fluid' && this.carouselLayout === 'full'"
>
  <aside
    *ngIf="title || description"
    class="content d-flex me-3
      justify-content-{{ layout.textPositionHorizontal }}
      align-items-{{ layout.textPositionVertical }}"
    [class.col-md-6]="carouselLayout === 'half'"
    [class.col-md-3]="carouselLayout === 'sidebar'"
  >
    <section
      class="text-section p-4"
      [class.text-start]="layout.textAlignment === 'left'"
      [class.text-center]="layout.textAlignment === 'center'"
      [class.text-end]="layout.textAlignment === 'right'"
    >
      <h2 class="carousel-header" *ngIf="title">{{ title }}</h2>
      <div class="description" *ngIf="description">
        <x-markdown-view [source]="description"></x-markdown-view>
      </div>
    </section>
  </aside>
  <aside
    class="media"
    [class.col-md-6]="carouselLayout === 'half'"
    [class.col-md-9]="carouselLayout === 'sidebar'"
  >
    <x-swiper
      [slideTemplate]="slideTemplate"
      [slides]="items"
      [swiperOptions]="carouselOptions"
      (onBreakpoint)="onBreakpoint($event)"
    >
      <ng-template #slideTemplate let-item>
        <a class="carousel-gallery-item" *ngIf="item.media" [xLink]="item.url" routerLink="">
          <div class="view-box">
            <x-content-media
              [media]="item.media"
              [width]="mediaWidth"
              [height]="mediaHeight"
            ></x-content-media>
          </div>
          <div class="item-button">
            <a mat-button class="mat-button">{{ item.title }}</a>
          </div>
        </a>
      </ng-template>
    </x-swiper>
  </aside>
</section>
