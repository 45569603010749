import { AccordionModule } from '@x/content/render/dynamic-components/accordion/accordion.module';
import { ActionBannerModule } from '@x/content/render/dynamic-components/action-banner/action-banner.module';
import { ActionGridModule } from '@x/content/render/dynamic-components/action-grid/action-grid.module';
import { ActionListModule } from '@x/content/render/dynamic-components/action-list/action-list.module';
import { AnchorModule } from '@x/content/render/dynamic-components/anchor/anchor.module';
import { BannerModule } from '@x/content/render/dynamic-components/banner/banner.module';
import { CardGridModule } from '@x/content/render/dynamic-components/card-grid/card-grid.module';
import { CarouselModule } from '@x/content/render/dynamic-components/carousel/carousel.module';
import { FaqModule } from '@x/content/render/dynamic-components/faq/faq.module';
import { FooterLinkListModule } from '@x/content/render/dynamic-components/footer-link-list/footer-link-list.module';
import { HtmlContainerModule } from '@x/content/render/dynamic-components/html-container/html-container.module';
import { SectionWithImageModule } from '@x/content/render/dynamic-components/section-with-image/section-with-image.module';
import { SectionOneModule } from '@x/content/render/dynamic-components/section/section-one.module';
import { SectionThreeModule } from '@x/content/render/dynamic-components/section/section-three.module';
import { SectionTwoModule } from '@x/content/render/dynamic-components/section/section-two.module';
import { SpacerModule } from '@x/content/render/dynamic-components/spacer/spacer.module';
import { EventListingModule } from './dynamic-components/event-listing/event-listing.module';
import { DynamicContentComponentMap } from './types/content-component.type';

export const CONTENT_DYNAMIC_COMPONENTS: DynamicContentComponentMap = {
  ComponentBrochureEventListing: () => EventListingModule,

  ComponentBrochureActionBanner: () => ActionBannerModule,

  ComponentBrochureActionGrid: () => ActionGridModule,

  ComponentBrochureActionList: () => ActionListModule,

  ComponentBrochureBanner: () => BannerModule,

  ComponentBrochureCardGrid: () => CardGridModule,

  ComponentBrochureCarousel: () => CarouselModule,

  ComponentBrochureFaq: () => FaqModule,

  ComponentBrochureSectionOne: () => SectionOneModule,

  ComponentBrochureSectionTwo: () => SectionTwoModule,

  ComponentBrochureSectionThree: () => SectionThreeModule,

  ComponentBrochureSectionWithImage: () => SectionWithImageModule,

  ComponentBrochureSpacer: () => SpacerModule,

  ComponentBrochureAnchor: () => AnchorModule,

  ComponentBrochureHtmlContainer: () => HtmlContainerModule,

  ComponentShopFooterLinkComponent: () => FooterLinkListModule,

  ComponentBrochureAccordion: () => AccordionModule,
};
