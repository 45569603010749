import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@x/common/cloudinary';
import { LinkModule } from '@x/common/link/link.module';
import { MarkdownModule } from '@x/common/markdown';
import { ContentMediaComponent } from '@x/content/render/components/content-media/content-media.component';
import { ContentRenderSharedModule } from '../../content-render-shared.module';
import { DynamicComponentModule } from '../../types/content-component.type';
import { CardGridComponent } from './card-grid.component';

@NgModule({
  declarations: [CardGridComponent],
  imports: [
    CloudinaryModule,
    CommonModule,
    MarkdownModule,
    LinkModule,
    ContentRenderSharedModule,
    RouterModule,
    ContentMediaComponent,
  ],
  exports: [CardGridComponent],
})
export class CardGridModule implements DynamicComponentModule<CardGridComponent> {
  getDynamicComponentType(): ComponentType<CardGridComponent> {
    return CardGridComponent;
  }
}
