import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { TMediaResponsiveConfig } from '@x/common/media-thumbnail/media-thumbnail.config';
import { ICardGridContent, ICardGridItemContent, TCardGridType } from '@x/content/client';
import { CONTENT_DATA, ThemeableComponent } from '@x/content/render';
import { CARD_GRID } from '@x/content/render/dynamic-components/card-grid/card-grid';
import { ContentMediaService } from '@x/content/render/services/content-media.service';

@Component({
  selector: 'x-card-grid, [x-card-grid]',
  templateUrl: './card-grid.component.html',
  host: { class: 'x-card-grid' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardGridComponent extends ThemeableComponent {
  @Input()
  set content(content: ICardGridContent) {
    this.title = content?.title;
    this.items = content.cardItems;
    this.background = content?.background;
    this.displayControl = content.displayControl;
    this.cardGridType = content.cardGridType ?? 'default';
    this.responsiveMediaConfig =
      this.contentMediaService.getResponsiveMedia(CARD_GRID)['card-grid'];
  }

  title?: string | null;
  items: ICardGridItemContent[] = [];
  cardGridType: TCardGridType;
  responsiveMediaConfig: TMediaResponsiveConfig;

  constructor(
    private contentMediaService: ContentMediaService,
    @Inject(CONTENT_DATA) @Optional() content: ICardGridContent,
  ) {
    super();
    if (content) this.content = content;
  }
}
