import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { TMediaResponsiveConfig } from '@x/common/media-thumbnail/media-thumbnail.config';
import {
  BannerType,
  IBannerContent,
  IButtonContent,
  IThemeLayout,
  IUploadFileContent,
  IVideoConfig,
  ThemeLayoutTextPositionVertical,
} from '@x/content/client';
import {
  CONTENT_DATA,
  CONTENT_RENDER_BRAND_CONFIG,
  ContentRenderBrandConfig,
  DynamicContentComponent,
  ThemeableComponent,
} from '@x/content/render';
import { BANNER_MEDIA_CONFIG } from '@x/content/render/dynamic-components/banner/banner';
import { ContentMediaService } from '@x/content/render/services/content-media.service';

@Component({
  selector: 'x-banner, [x-banner]',
  templateUrl: './banner.component.html',
  host: { class: 'x-banner' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent
  extends ThemeableComponent
  implements DynamicContentComponent<IBannerContent>
{
  set content(content: IBannerContent) {
    this.title = content.title;
    this.description = content?.description;
    this.whiteText = Boolean(content?.whiteText);
    this.buttons = content?.buttons;
    this.layout = content?.layout ?? this.layout;
    this.alignment = content?.layout?.textPositionVertical ?? 'center';
    this.mainMedia = content?.media;
    this.videoConfig = content?.videoConfig;
    this._secondaryMediaForMobile = content?.secondaryMedia;
    this._useSecondaryMediaForMobile = Boolean(content?.useSecondaryMediaForMobile);
    this.displayControl = content.displayControl;
    this.responsiveMediaConfig =
      this.contentMediaService.getResponsiveMedia(BANNER_MEDIA_CONFIG)[
        `banner-${content.bannerHeight}`
      ];
  }

  title?: string | null;
  description?: string | null;
  whiteText: boolean = false;
  bannerType: BannerType = 'default';
  alignment: ThemeLayoutTextPositionVertical | null = 'center';
  mainMedia: IUploadFileContent | null | undefined;
  layout: IThemeLayout = {
    container: 'fluid',
    textPositionHorizontal: 'start',
    textPositionVertical: 'center',
    textAlignment: 'center',
  };
  videoConfig?: IVideoConfig | null;
  buttons?: Array<IButtonContent> | null;
  responsiveMediaConfig: TMediaResponsiveConfig;
  // componentHeight: BannerHeight = 'full';

  private _secondaryMediaForMobile: IUploadFileContent | null | undefined;
  private _useSecondaryMediaForMobile: boolean | null;
  get secondaryMediaForMobile(): IUploadFileContent | null {
    return this._useSecondaryMediaForMobile && this._secondaryMediaForMobile
      ? this._secondaryMediaForMobile
      : null;
  }

  constructor(
    @Inject(CONTENT_DATA)
    @Optional()
    content: IBannerContent,
    @Inject(CONTENT_RENDER_BRAND_CONFIG)
    @Optional()
    public config: ContentRenderBrandConfig,
    private contentMediaService: ContentMediaService,
  ) {
    super();
    if (content) this.content = content;
  }
}
