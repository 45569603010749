import { createNgModule, Inject, Injectable, Injector, ViewContainerRef } from '@angular/core';
import {
  CONTENT_DATA,
  DYNAMIC_CONTENT_COMPONENT_MAP,
  DynamicContentComponentMap,
  DynamicContentData,
  DynamicContentModuleType,
} from '@x/content/render';

@Injectable()
export class DynamicContentComponentFactoryService {
  constructor(
    @Inject(DYNAMIC_CONTENT_COMPONENT_MAP)
    private componentMap: DynamicContentComponentMap,
  ) {}

  public async createComponentInViewContainer(
    viewContainer: ViewContainerRef,
    content: DynamicContentData,
    parentInjector?: Injector,
  ) {
    const typename = content.__typename;

    const module = await this.loadModule(typename);
    const moduleRef = createNgModule(module, parentInjector);
    const componentType = moduleRef.instance.getDynamicComponentType();

    const injector = Injector.create({
      providers: [
        { provide: ViewContainerRef, useValue: viewContainer },
        { provide: CONTENT_DATA, useValue: content },
      ],
      parent: moduleRef.injector,
    });

    viewContainer.clear();
    const comp = viewContainer.createComponent(componentType, {
      injector,
    });
    comp.changeDetectorRef.detectChanges();
  }

  public async loadModule(typename: string): Promise<DynamicContentModuleType> {
    const modulePromise = this.componentMap[typename];

    if (!modulePromise) {
      throw new Error(
        `DynamicContentComponentFactoryService: Dynamic content module '${typename}', not registered.`,
      );
    }

    return await modulePromise();
  }
}
