import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { IAccordionContent, IExpansionPanelContent } from '@x/content/client';
import { CONTENT_DATA, DynamicContentComponent } from '@x/content/render';

@Component({
  selector: 'x-accordion',
  templateUrl: './accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-accordion' },
})
export class AccordionComponent implements DynamicContentComponent<IAccordionContent> {
  @Input()
  set content(content: IAccordionContent) {
    this._content = content;
    this.title = content.title;
    this.description = content.description;
    this.expansionPanels = content.expansionPanel ?? [];
  }

  title?: string | null;

  description?: string | null;

  expansionPanels: IExpansionPanelContent[] = [];

  _content: IAccordionContent;

  constructor(@Inject(CONTENT_DATA) @Optional() content: IAccordionContent) {
    if (content) this.content = content;
  }
}
