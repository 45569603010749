import { BannerHeight } from '@x/content/client';
import { TContentMediaConfig } from '@x/content/render/types/content-media.config';

export const BANNER_MEDIA_CONFIG: TContentMediaConfig<'banner', BannerHeight> = {
  'banner-narrow': {
    '800/640': ['XS', 'SM'],
    '1400/318': ['MD', 'LG'],
    '1600/363': ['XL', 'XXL'],
  },
  'banner-half': {
    '800/640': ['XS', 'SM'],
    '1400/512': ['MD', 'LG'],
    '1600/585': ['XL', 'XXL'],
  },
  'banner-full': {
    '800/640': ['XS', 'SM'],
    '1400/700': ['MD', 'LG'],
    '1600/800': ['XL', 'XXL'],
  },
};
