import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { TMediaResponsiveConfig } from '@x/common/media-thumbnail/media-thumbnail.config';
import { IActionBannerContent, IActionBannerItemContent } from '@x/content/client';
import { CONTENT_DATA, ThemeableComponent } from '@x/content/render';
import { ACTION_BANNER_MEDIA_CONFIG } from '@x/content/render/dynamic-components/action-banner/action-banner';
import { ContentMediaService } from '@x/content/render/services/content-media.service';

@Component({
  selector: 'x-action-banner, [x-action-banner]',
  templateUrl: './action-banner.component.html',
  host: { class: 'x-action-banner' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionBannerComponent extends ThemeableComponent {
  @Input()
  set content(content: IActionBannerContent) {
    this.title = content?.title;
    this.description = content?.description;
    this.items = content.actionBannerItems ?? [];
    this.displayControl = content.displayControl;
    this.responsiveMediaConfig = this.contentMediaService.getResponsiveMedia(
      ACTION_BANNER_MEDIA_CONFIG,
    )['action-banner'];
  }

  title?: string | null;
  description?: string | null;
  items: IActionBannerItemContent[] = [];
  responsiveMediaConfig: TMediaResponsiveConfig;

  constructor(
    private contentMediaService: ContentMediaService,
    @Inject(CONTENT_DATA) @Optional() content?: IActionBannerContent,
  ) {
    super();
    if (content) this.content = content;
  }
}
