<div
  class="section one mat-typography py-5 py-lg-6 background-{{ this.backgroundColor }} text-pos-{{
    this.layout.textPositionHorizontal
  }}"
>
  <div class="text-view align-{{ this.layout.textAlignment }}">
    <h2 class="text-center" *ngIf="title">{{ title }}</h2>
    <div class="row g-0">
      <div class="col column-one">
        <x-markdown-view *ngIf="description" [source]="description"></x-markdown-view>
      </div>
    </div>
    <section
      *ngIf="buttons && buttons.length"
      class="button-section align-{{ this.layout.textAlignment }}"
    >
      <div class="buttons">
        <div class="button" *ngFor="let button of buttons">
          <x-button [content]="button"></x-button>
        </div>
      </div>
    </section>
  </div>
</div>
