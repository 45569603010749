import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ThemeableComponent } from '@x/content/render/components/themeable.component';
import { ContentRenderSharedModule } from './content-render-shared.module';
import { ContentRenderModuleConfig, CONTENT_RENDER_BRAND_CONFIG } from './content-render.config';
import { CONTENT_DYNAMIC_COMPONENTS } from './core-dynamic-components';
import { CORE_DYNAMIC_DIALOGS } from './core-dynamic-dialogs';
import { ContentDialogService } from './services/content-dialog.service';
import { DynamicContentComponentFactoryService } from './services/dynamic-content-component-factory.services';
import { DynamicDialogComponentFactoryService } from './services/dynamic-dialog-component-factory.service';
import { DYNAMIC_CONTENT_COMPONENT_MAP } from './types/content-component.type';
import { DYNAMIC_DIALOG_MODULE_MAP } from './types/dynamic-dialog-component.type';

@NgModule({
  imports: [CommonModule, MatDialogModule, ContentRenderSharedModule],
  declarations: [ThemeableComponent],
  exports: [ContentRenderSharedModule],
})
export class ContentRenderModule {
  static forRoot(config: ContentRenderModuleConfig): ModuleWithProviders<ContentRenderModule> {
    return {
      ngModule: ContentRenderModule,
      providers: [
        {
          provide: DYNAMIC_CONTENT_COMPONENT_MAP,
          useValue: {
            ...CONTENT_DYNAMIC_COMPONENTS,
            ...config.components,
          },
        },
        {
          provide: DYNAMIC_DIALOG_MODULE_MAP,
          useValue: CORE_DYNAMIC_DIALOGS,
        },
        {
          provide: CONTENT_RENDER_BRAND_CONFIG,
          useValue: config.brand,
        },
        ContentDialogService,
        DynamicContentComponentFactoryService,
        DynamicDialogComponentFactoryService,
      ],
    };
  }
}
