@if (link.openInNewTab) {
  <a [href]="link.url" target="_blank">
    <fa-icon *ngIf="link.icon" [icon]="link.icon" xColor="grey"></fa-icon>
    <span>{{ link.text }}</span>
  </a>
} @else {
  <a [xLink]="link.url" routerLink>
    <fa-icon *ngIf="link.icon" [icon]="link.icon" xColor="grey"></fa-icon>
    <span>{{ link.text }}</span>
  </a>
}
