import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaModule } from '@x/common/media';
import { MediaThumbnailComponent } from '@x/common/media-thumbnail/media-thumbnail.component';
import {
  IMediaSize,
  TMediaResponsiveConfig,
} from '@x/common/media-thumbnail/media-thumbnail.config';
import { MediaThumbnailService } from '@x/common/media-thumbnail/media-thumbnail.service';
import { CloudinaryPlayerComponent } from '@x/common/media/cloudinary-player/cloudinary-player.component';
import { TVideoMode } from '@x/common/media/cloudinary-player/cloudinary-player.config';
import { IUploadFileContent } from '@x/content/client';
import { Observable } from 'rxjs';

@Component({
  selector: 'x-content-media',
  standalone: true,
  imports: [MediaModule, AsyncPipe, CloudinaryPlayerComponent, MediaThumbnailComponent],
  templateUrl: './content-media.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'x-content-media' },
})
export class ContentMediaComponent {
  @Input() media?: IUploadFileContent | undefined | null;
  @Input() responsiveMediaConfig?: TMediaResponsiveConfig;
  @Input() mode?: TVideoMode | undefined | null;
  @Input() width?: number;
  @Input() height?: number;

  videoMediaConfig$?: Observable<IMediaSize>;

  constructor(private mediaThumbnailService: MediaThumbnailService) {}

  ngOnInit(): void {
    this.videoMediaConfig$ = this.mediaThumbnailService.observeDimensions$(
      this.responsiveMediaConfig,
      this.width,
      this.height,
    );
  }
}
