<div
  class="section two mat-typography py-5 py-lg-6 column-dividers background-{{
    this.backgroundColor
  }}"
>
  <div class="container text-center">
    <h2 *ngIf="title" class="mb-4">{{ title }}</h2>
    <div class="row">
      <div class="col-12 col-md-6 text-md-end column-one px-5">
        <x-markdown-view *ngIf="descriptionOne" [source]="descriptionOne"></x-markdown-view>
        <div>
          <section class="button-section align-{{ this.layout.textAlignment }}">
            <div class="buttons">
              <div class="button" *ngFor="let button of buttonOne">
                <x-button [content]="button"></x-button>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="col-12 col-md-6 text-md-start column-two px-5">
        <x-markdown-view *ngIf="descriptionTwo" [source]="descriptionTwo"></x-markdown-view>
        <div>
          <section class="button-section align-{{ this.layout.textAlignment }}">
            <div class="buttons">
              <div class="button" *ngFor="let button of buttonTwo">
                <x-button [content]="button"></x-button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
