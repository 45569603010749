<div
  class="container-fluid py-5 px-lg-6 py-lg-5 px-xl-8 py-xl-5 mat-typography text-center"
  *ngIf="title || description"
>
  <h2>{{ title }}</h2>
  <x-markdown-view *ngIf="description" [source]="description"></x-markdown-view>
</div>
<div class="container-fluid mat-typography text-center">
  <div class="row d-flex flex-wrap">
    <div class="col action-banner-item p-0" *ngFor="let action of items">
      <a
        [xLink]="action?.url"
        *ngIf="action?.media"
        [attr.aria-label]="action?.media?.alternativeText || title"
      >
        <x-content-media
          [media]="action.media"
          [responsiveMediaConfig]="responsiveMediaConfig"
        ></x-content-media>
      </a>
      <x-button [content]="{ url: action?.url ?? '', text: action?.text ?? '' }"></x-button>
    </div>
  </div>
</div>
