import { Pipe, PipeTransform } from '@angular/core';
import { IThemeLayout } from '@x/content/client';

@Pipe({
  name: 'textPositionClass',
  standalone: true,
})
export class TextPositionClassPipe implements PipeTransform {
  transform(layout: IThemeLayout): string | null {
    const classes = [];

    // Horizontal positioning
    if (layout.textPositionHorizontal === 'start') {
      classes.push('justify-content-start');
    } else if (layout.textPositionHorizontal === 'center') {
      classes.push('justify-content-center');
    } else if (layout.textPositionHorizontal === 'end') {
      classes.push('justify-content-end');
    }

    // Vertical positioning
    if (layout.textPositionVertical === 'top') {
      classes.push('align-items-start');
    } else if (layout.textPositionVertical === 'center') {
      classes.push('align-items-center');
    } else if (layout.textPositionVertical === 'bottom') {
      classes.push('align-items-end');
    }

    return classes.join(' ');
  }
}
