@if (wr.isServer) {
  <x-media-thumbnail [url]="url" [responsiveConfig]="responsiveMediaConfig"></x-media-thumbnail>
} @else {
  <video
    xObserveVisibility
    (isVisible)="initPlayer()"
    #videoPlayer
    muted
    class="cld-video-player cld-fluid"
    [poster]="url | cloudImage: { width: width, height: height }"
  ></video>
}
