<div class="container py-6 py-md-8 mat-typography text-center">
  <h2 *ngIf="title">{{ title }}</h2>
  <x-markdown-view *ngIf="description" [source]="description"></x-markdown-view>
  <mat-expansion-panel hideToggle *ngFor="let item of items" class="text-start">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ item.question }}</mat-panel-title>
    </mat-expansion-panel-header>
    <x-markdown-view [source]="item.answer"></x-markdown-view>
  </mat-expansion-panel>
</div>
