<div class="mat-typography py-5 py-lg-6 event-listing-inner">
  <div class="container text-center pb-5" *ngIf="title && description">
    <h2>{{ title }}</h2>
    <div class="row">
      <div class="col column-one px-5">
        <x-markdown-view *ngIf="description" [source]="description"></x-markdown-view>
      </div>
    </div>
  </div>
  <div *ngIf="events$ | async as collection" class="container-fluid px-lg-6 px-xl-8">
    <div class="row justify-content-center">
      <div
        class="col-12 col-md-6 col-lg-4 mb-4 mobile-text-center mat-typography event-listing-item"
        *ngFor="let event of collection.items"
      >
        <a [routerLink]="['/events', event.slug]">
          <x-content-media [media]="event.image" [width]="500" [height]="500"></x-content-media>
          <p>{{ event.dateTime }}</p>
          <h3>{{ event.title }}</h3>
          <p>{{ event.description }}</p>
        </a>
        <a [routerLink]="['/events', event.slug]" [text]="event.bookingCtaText ?? 'Book now'"></a>
      </div>
    </div>
  </div>
</div>
