import { InjectionToken, Type } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');
export const DYNAMIC_DIALOG_MODULE_MAP = new InjectionToken<DynamicDialogModuleMap>(
  'DYNAMIC_DIALOG_COMPONENT_MAP',
);

export type DynamicDialogModuleType = Type<DynamicDialogComponentModule>;
export type DynamicDialogModuleMap = Record<string, () => Promise<DynamicDialogModuleType>>;
export type DynamicDialogConfig<D = any> = MatDialogConfig<D>;

export interface DynamicDialogComponentModule {
  getDialogType(): Type<any>;
  getDialogConfig(args?: any): DynamicDialogConfig;
}
