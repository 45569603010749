<!--Desktop-->
<div class="visible-lg-up">
  <div class="container py-5 d-flex">
    <div class="footer-menu" *ngFor="let category of linkCategories">
      <div class="menu mat-display-2">
        <header class="mat-subtitle-2 mb-3 text-uppercase" *ngIf="category.label">
          {{ category.label }}
        </header>
        <ul>
          <li class="mat-display-2" *ngFor="let link of category.categoryLinks">
            <x-footer-link [link]="link"></x-footer-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!--Mobile-->
<mat-expansion-panel
  class="visible-lg-down"
  *ngFor="let category of linkCategories"
  [expanded]="false"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="label mat-headline-6">{{ category.label }}</div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ul>
    <li class="mat-body-1" *ngFor="let link of category.categoryLinks">
      <x-footer-link [link]="link"></x-footer-link>
    </li>
  </ul>
</mat-expansion-panel>
