import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { ISpacerContent, VerticalSpace } from '@x/content/client';
import {
  CONTENT_DATA,
  CONTENT_RENDER_BRAND_CONFIG,
  ContentRenderBrandConfig,
  DynamicContentComponent,
} from '@x/content/render';

@Component({
  selector: 'x-spacer, [x-spacer]',
  templateUrl: './spacer.component.html',
  host: { class: 'x-spacer' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class SpacerComponent implements DynamicContentComponent<ISpacerContent> {
  @Input()
  set content(content: ISpacerContent) {
    this.space = content.verticalSpace;
  }

  space?: VerticalSpace | null;

  constructor(
    @Inject(CONTENT_DATA)
    content: ISpacerContent,
    @Inject(CONTENT_RENDER_BRAND_CONFIG)
    public config: ContentRenderBrandConfig,
  ) {
    this.content = content;
  }
}
