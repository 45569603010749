import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloudinaryModule } from '@x/common/cloudinary';
import { LinkModule } from '@x/common/link/link.module';
import { MarkdownModule } from '@x/common/markdown';
import { ContentRenderSharedModule } from '../../content-render-shared.module';
import { DynamicComponentModule } from '../../types/content-component.type';
import { SectionTwoComponent } from './section-two.component';

@NgModule({
  declarations: [SectionTwoComponent],
  imports: [CloudinaryModule, CommonModule, MarkdownModule, LinkModule, ContentRenderSharedModule],
  exports: [SectionTwoComponent],
})
export class SectionTwoModule implements DynamicComponentModule<SectionTwoComponent> {
  getDynamicComponentType(): ComponentType<SectionTwoComponent> {
    return SectionTwoComponent;
  }
}
