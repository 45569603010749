@if (mainMedia) {
  <div class="banner-wrapper" [class.container]="this.layout.container === 'contain'">
    <!--media-->
    @if (secondaryMediaForMobile) {
      <!--desktop use main media-->
      <x-content-media
        class="visible-lg-up"
        [media]="mainMedia"
        [responsiveMediaConfig]="responsiveMediaConfig"
        [mode]="videoConfig?.mode"
      ></x-content-media>

      <!--secondary media (image only) for mobile-->
      <x-content-media
        class="visible-lg-down"
        [media]="secondaryMediaForMobile"
        [responsiveMediaConfig]="responsiveMediaConfig"
        [mode]="videoConfig?.mode"
      ></x-content-media>
    } @else {
      <x-content-media
        [media]="mainMedia"
        [responsiveMediaConfig]="responsiveMediaConfig"
        [mode]="videoConfig?.mode"
      ></x-content-media>
    }

    <!--content-->
    <div class="visible-lg-up">
      <div class="card-wrapper" *ngIf="title || description">
        <div class="card-boundary container {{ layout | textPositionClass }}">
          <div
            class="card mat-typography px-5 px-lg-0 py-5"
            [class.px-lg-3]="layout.container === 'contain'"
          >
            <div
              class="content d-flex flex-column {{ layout.textAlignment | textAlignClass }}"
              [class.white-text]="whiteText"
            >
              <h2 *ngIf="title" [class.white-text]="whiteText">{{ title }}</h2>
              <x-markdown-view
                class="mt-3"
                *ngIf="description"
                [source]="description"
              ></x-markdown-view>
              <section class="button-section mt-3">
                <div class="buttons">
                  <div class="button" *ngFor="let button of buttons">
                    <x-button [content]="button"></x-button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
