import { Pipe, PipeTransform } from '@angular/core';
import { ThemeLayoutTextAlignment } from '@x/content/client';

@Pipe({
  name: 'textAlignClass',
  standalone: true,
})
export class TextAlignClassPipe implements PipeTransform {
  transform(value: ThemeLayoutTextAlignment | null | undefined): string | null {
    if (!value) return null;

    switch (value) {
      case 'center': {
        return 'text-center';
      }
      case 'right': {
        return 'text-end';
      }
      default: {
        return 'text-start';
      }
    }
  }
}
