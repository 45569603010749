import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MarkdownModule } from '@x/common/markdown';
import { ContentRenderSharedModule } from '../../content-render-shared.module';
import { DynamicComponentModule } from '../../types/content-component.type';
import { AccordionComponent } from './accordion.component';

@NgModule({
  declarations: [AccordionComponent],
  imports: [CommonModule, MarkdownModule, ContentRenderSharedModule, MatExpansionModule],
  exports: [AccordionComponent],
})
export class AccordionModule implements DynamicComponentModule<AccordionComponent> {
  getDynamicComponentType(): ComponentType<AccordionComponent> {
    return AccordionComponent;
  }
}
