import { CarouselLayout, CarouselType } from '@x/content/client';
import { SwiperOptions } from 'swiper/types/swiper-options';

const DEFAULT_CAROUSEL_OPTIONS: SwiperOptions = {
  navigation: true,
  scrollbar: { draggable: true },
};

export const TICKER_TAPE_LOOP_OPTIONS: SwiperOptions = {
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
    waitForTransition: false,
  },
};

export const CAROUSEL_OPTIONS_MAP: Record<CarouselType, SwiperOptions> = {
  default: {
    ...DEFAULT_CAROUSEL_OPTIONS,
  },
  tickertape: {
    ...TICKER_TAPE_LOOP_OPTIONS,
    threshold: 25,
    navigation: true,
  },
  banner: {
    shortSwipes: false,
    ...DEFAULT_CAROUSEL_OPTIONS,
  },
};

export const CAROUSEL_LAYOUT_BREAKPOINTS: Record<CarouselLayout, SwiperOptions> = {
  full: {
    breakpoints: {
      0: {
        slidesPerView: 2.15,
        spaceBetween: 10,
        shortSwipes: true,
        threshold: 10,
      },
      476: {
        slidesPerView: 2.15,
        spaceBetween: 10,
        shortSwipes: true,
      },
      668: {
        slidesPerView: 3.15,
        spaceBetween: 10,
        shortSwipes: true,
      },
      892: {
        slidesPerView: 4.15,
        spaceBetween: 10,
        shortSwipes: false,
      },
      1300: {
        slidesPerView: 5.15,
        spaceBetween: 10,
        shortSwipes: false,
      },
      1620: {
        slidesPerView: 6.15,
        spaceBetween: 10,
        shortSwipes: false,
      },
    },
  },
  sidebar: {
    breakpoints: {
      676: {
        slidesPerView: 1.15,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 2.15,
        spaceBetween: 10,
      },
      1092: {
        slidesPerView: 3.15,
        spaceBetween: 10,
      },
      1400: {
        slidesPerView: 4.15,
        spaceBetween: 10,
      },
      1920: {
        slidesPerView: 5.15,
        spaceBetween: 10,
      },
    },
  },
  half: {
    breakpoints: {
      576: {
        slidesPerView: 1.15,
        spaceBetween: 0,
      },
      668: {
        slidesPerView: 1.15,
        spaceBetween: 0,
      },
      1092: {
        slidesPerView: 2.15,
        spaceBetween: 10,
      },
      1400: {
        slidesPerView: 2.15,
        spaceBetween: 10,
      },
      1920: {
        slidesPerView: 3.15,
        spaceBetween: 10,
      },
    },
  },
};
