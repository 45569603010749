import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { TMediaResponsiveConfig } from '@x/common/media-thumbnail/media-thumbnail.config';
import {
  IButtonContent,
  ISectionWithImageContent,
  IThemeLayout,
  IUploadFileContent,
  IVideoConfig,
  SectionWithImageHeight,
  SectionWithImageType,
} from '@x/content/client';
import { CONTENT_DATA, ThemeableComponent } from '@x/content/render';
import { SECTION_WITH_IMAGE_CONFIG } from '@x/content/render/dynamic-components/section-with-image/section-with-image';
import { ContentMediaService } from '@x/content/render/services/content-media.service';

@Component({
  selector: 'x-section-with-image, [x-section-with-image]',
  templateUrl: './section-with-image.component.html',
  host: { class: 'x-section-with-image' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class SectionWithImageComponent extends ThemeableComponent {
  @Input()
  set content(content: ISectionWithImageContent) {
    this.title = content.title;
    this.description = content.description;
    this.buttons = content.buttons || null;
    this.mainMedia = content.media;
    this.videoConfig = content.videoConfig;

    if (content.useSecondaryMediaForMobile) {
      this.secondaryMediaForMobile = content.secondaryMedia;
    }

    this.textPosition = content.sectionWithImageLayout ?? 'start';
    this.background = content.background;
    this.layout = content.layout ?? this.layout;
    this.sectionWithImageType = content.sectionWithImageType ?? 'half';
    this.sectionWithImageHeight = content.height;
    this.displayControl = content.displayControl;
    this.responsiveMediaConfig =
      this.contentMediaService.getResponsiveMedia(SECTION_WITH_IMAGE_CONFIG)[
        `${this.sectionWithImageType}-${this.sectionWithImageHeight}`
      ];
  }

  title?: string | null;
  description?: string | null;
  textPosition?: string | null = 'end';
  buttons?: Array<IButtonContent> | null;
  mainMedia?: IUploadFileContent | null;
  secondaryMediaForMobile?: IUploadFileContent | null;
  videoConfig?: IVideoConfig | null;
  responsiveMediaConfig: TMediaResponsiveConfig;

  private _layout: IThemeLayout = {
    container: 'fluid',
    textAlignment: 'left',
    textPositionHorizontal: 'center',
    textPositionVertical: 'center',
  };
  set layout(layout: IThemeLayout) {
    if (layout) {
      let { textPositionHorizontal, textPositionVertical, textAlignment, container } = layout;

      // if (textAlignment === 'left') textAlignment = 'start';
      // if (textAlignment === 'right') textAlignment = 'end';

      this._layout = {
        ...this._layout,
        ...(textPositionHorizontal && { textPositionHorizontal }),
        ...(textPositionVertical && { textPositionVertical }),
        ...(textAlignment && { textAlignment }),
        ...(container && { container }),
      };
    }
  }
  get layout(): IThemeLayout {
    return this._layout;
  }

  sectionWithImageType: SectionWithImageType;
  sectionWithImageHeight: SectionWithImageHeight;

  constructor(
    private contentMediaService: ContentMediaService,
    @Inject(CONTENT_DATA)
    @Optional()
    content: ISectionWithImageContent,
  ) {
    super();
    if (content) this.content = content;
  }
}
