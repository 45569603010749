import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { SchemaMarkupService } from '@x/common/meta';
import { IFaqContent, IFaqItemContent } from '@x/content/client';
import { CONTENT_DATA } from '@x/content/render';

@Component({
  selector: 'x-faq, [x-faq]',
  templateUrl: './faq.component.html',
  host: { class: 'x-faq' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class FaqComponent {
  @Input()
  set content(content: IFaqContent) {
    this.title = content?.title;
    this.description = content?.description;
    this.items = content.faqItems;

    this.schemaMarkupService.add(
      'Question',
      this.items.map((item) => ({
        text: item.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.answer,
        },
      })),
    );
  }

  title?: string | null;

  description?: string | null;

  items: IFaqItemContent[] = [];

  constructor(
    private schemaMarkupService: SchemaMarkupService,
    @Inject(CONTENT_DATA)
    content: IFaqContent,
  ) {
    this.content = content;
  }
}
