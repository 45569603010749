import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { IHtmlContainerContent } from '@x/content/client';
import { CONTENT_DATA, DynamicContentComponent } from '@x/content/render';

@Component({
  selector: 'x-html-container',
  templateUrl: './html-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-html-container',
  },
})
export class HtmlContainerComponent implements DynamicContentComponent<IHtmlContainerContent> {
  @Input()
  set content(content: IHtmlContainerContent) {
    this._content = content;
  }

  _content: IHtmlContainerContent;

  constructor(@Inject(CONTENT_DATA) @Optional() content: IHtmlContainerContent) {
    if (content) this.content = content;
  }
}
