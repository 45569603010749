import { ComponentType } from '@angular/cdk/portal';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloudinaryModule } from '@x/common/cloudinary';
import { LinkModule } from '@x/common/link/link.module';
import { LoaderModule } from '@x/common/loader';
import { MarkdownModule } from '@x/common/markdown';
import { MediaModule } from '@x/common/media';
import { ResponsiveModule } from '@x/common/responsive';
import { DynamicComponentModule } from '@x/content/render';
import { ContentMediaComponent } from '@x/content/render/components/content-media/content-media.component';
import { TextAlignClassPipe } from '@x/content/render/pipes/text-align-class.pipe';
import { TextPositionClassPipe } from '@x/content/render/pipes/text-position-class.pipe';
import { ContentRenderSharedModule } from '../../content-render-shared.module';
import { BannerComponent } from './banner.component';

@NgModule({
  declarations: [BannerComponent],
  imports: [
    CloudinaryModule,
    CommonModule,
    MarkdownModule,
    LinkModule,
    ContentRenderSharedModule,
    ResponsiveModule,
    MediaModule,
    NgOptimizedImage,
    LoaderModule,
    ContentMediaComponent,
    TextAlignClassPipe,
    TextPositionClassPipe,
  ],
  exports: [BannerComponent],
})
export class BannerModule implements DynamicComponentModule<BannerComponent> {
  getDynamicComponentType(): ComponentType<BannerComponent> {
    return BannerComponent;
  }
}
