import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { TMediaResponsiveConfig } from '@x/common/media-thumbnail/media-thumbnail.config';
import { IActionListContent, IActionListItemContent, IThemeLayout } from '@x/content/client';
import { CONTENT_DATA, ThemeableComponent } from '@x/content/render';
import { ACTION_LIST_MEDIA_CONFIG } from '@x/content/render/dynamic-components/action-list/action-list';
import { ContentMediaService } from '@x/content/render/services/content-media.service';

@Component({
  selector: 'x-action-list, [x-action-list]',
  templateUrl: './action-list.component.html',
  host: { class: 'x-action-list' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ActionListComponent extends ThemeableComponent {
  @Input()
  set content(content: IActionListContent) {
    this.title = content?.title;
    this.description = content?.description;
    this.items = content?.actionListItems;
    this.layout = content?.layout || this.layout;
    this.displayControl = content.displayControl;
    this.responsiveMediaConfig =
      this.contentMediaService.getResponsiveMedia(ACTION_LIST_MEDIA_CONFIG)['action-list'];
  }

  title?: string | null;
  description?: string | null;
  layout: IThemeLayout = {
    container: 'fluid',
    textPositionHorizontal: 'start',
    textPositionVertical: 'center',
    textAlignment: 'center',
  };

  items: IActionListItemContent[] = [];
  responsiveMediaConfig: TMediaResponsiveConfig;

  constructor(
    private contentMediaService: ContentMediaService,
    @Inject(CONTENT_DATA) content: IActionListContent,
  ) {
    super();
    this.content = content;
  }
}
