import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@x/common/cloudinary';
import { LinkModule } from '@x/common/link/link.module';
import { MarkdownModule } from '@x/common/markdown';
import { ContentMediaComponent } from '@x/content/render/components/content-media/content-media.component';
import { DynamicComponentModule } from '../../types/content-component.type';
import { EventListingComponent } from './event-listing.component';

@NgModule({
  declarations: [EventListingComponent],
  imports: [
    CloudinaryModule,
    CommonModule,
    MarkdownModule,
    LinkModule,
    RouterModule,
    ContentMediaComponent,
  ],
  exports: [EventListingComponent],
})
export class EventListingModule implements DynamicComponentModule<EventListingComponent> {
  getDynamicComponentType(): ComponentType<EventListingComponent> {
    return EventListingComponent;
  }
}
