@if (media) {
  @switch (media.type) {
    @case ('image') {
      <x-media-thumbnail
        [url]="media.url"
        [width]="width"
        [height]="height"
        [loader]="true"
        [responsiveConfig]="responsiveMediaConfig"
        crop="fill"
      ></x-media-thumbnail>
    }
    @case ('video') {
      <x-cloudinary-player
        [mode]="mode"
        [url]="media.url"
        [width]="(videoMediaConfig$ | async)?.width"
        [height]="(videoMediaConfig$ | async)?.height"
        [responsiveMediaConfig]="responsiveMediaConfig"
      ></x-cloudinary-player>
    }
  }
}
