import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarkdownModule } from '@x/common/markdown';
import { DynamicComponentModule } from '../../types/content-component.type';
import { HtmlContainerComponent } from './html-container.component';

@NgModule({
  declarations: [HtmlContainerComponent],
  imports: [CommonModule, MarkdownModule],
  exports: [HtmlContainerComponent],
})
export class HtmlContainerModule implements DynamicComponentModule<HtmlContainerComponent> {
  getDynamicComponentType(): ComponentType<HtmlContainerComponent> {
    return HtmlContainerComponent;
  }
}
