import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconModule } from '@x/common/icon';
import { LinkModule } from '@x/common/link';
import { ResponsiveModule } from '@x/common/responsive';
import { FooterLinkComponent } from '@x/content/render/dynamic-components/footer-link/footer-link.component';
import { DynamicComponentModule } from '../../types/content-component.type';
import { FooterLinkListComponent } from './footer-link-list.component';

@NgModule({
  declarations: [FooterLinkListComponent],
  imports: [
    CommonModule,
    ResponsiveModule,
    MatExpansionModule,
    FontAwesomeModule,
    IconModule,
    LinkModule,
    FooterLinkComponent,
  ],
  exports: [FooterLinkListComponent],
})
export class FooterLinkListModule implements DynamicComponentModule<FooterLinkListComponent> {
  getDynamicComponentType(): ComponentType<FooterLinkListComponent> {
    return FooterLinkListComponent;
  }
}
