import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  ContentCollection,
  EventPageService,
  ICollectionFilter,
  IEventListingContent,
  IEventPageItemContent,
} from '@x/content/client';
import { DynamicContentComponent, ThemeableComponent } from '@x/content/render';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'x-event-listing, [x-event-listing]',
  templateUrl: './event-listing.component.html',
  host: { class: 'x-event-listing' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventListingComponent
  extends ThemeableComponent
  implements DynamicContentComponent<IEventListingContent>, OnInit
{
  @Input()
  set content(content: IEventListingContent) {
    this.title = content?.title;
    this.description = content?.description;
    this.background = content?.background;
    this.collectionFilter = content.collectionFilter;
  }

  title?: string | null;

  description?: string | null;

  set collectionFilter(filter: ICollectionFilter) {
    this.collectionFilter$.next(filter);
  }

  collectionFilter$ = new BehaviorSubject<ICollectionFilter | null>(null);
  events$: Observable<ContentCollection<IEventPageItemContent>>;

  constructor(private service: EventPageService) {
    super();
  }

  ngOnInit(): void {
    this.events$ = this.collectionFilter$.pipe(
      switchMap((filter) => {
        if (filter) {
          return this.service.fetchItems(filter);
        }

        return of({ items: [], totalItemsCount: 0 });
      }),
    );
  }
}
