import { Component, Inject, Input, Optional } from '@angular/core';
import { IAnchorContent } from '@x/content/client';
import { CONTENT_DATA, DynamicContentComponent } from '@x/content/render';

@Component({
  selector: 'x-anchor, [x-anchor]',
  templateUrl: 'anchor.component.html',
  host: { class: 'x-anchor' },
})
export class AnchorComponent implements DynamicContentComponent<IAnchorContent> {
  @Input()
  set content(content: IAnchorContent) {
    this.anchorId = content.anchorId;
  }

  anchorId: string;

  constructor(
    @Inject(CONTENT_DATA)
    @Optional()
    content: IAnchorContent,
  ) {
    if (content) this.content = content;
  }
}
