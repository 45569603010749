import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { CloudinaryModule } from '@cloudinary/ng';
import { WindowRef } from '@x/common/browser';
import { ObserveVisibilityDirective } from '@x/common/browser/directives/observe-visibility.directive';
import {
  CloudinaryUrl,
  CommonTransformationOptions,
  deconstructCloudinaryUrl,
} from '@x/common/cloudinary';
import { CloudImagePipe } from '@x/common/cloudinary/cloud-image.pipe';
import { MediaThumbnailComponent } from '@x/common/media-thumbnail/media-thumbnail.component';
import { TMediaResponsiveConfig } from '@x/common/media-thumbnail/media-thumbnail.config';
import {
  CloudinaryPlayerConfig,
  TVideoMode,
  VIDEO_MODE_OPTIONS,
} from '@x/common/media/cloudinary-player/cloudinary-player.config';

export type TVideoAspectRatio = `${number}:${number}`;

@Component({
  selector: 'x-cloudinary-player',
  standalone: true,
  templateUrl: './cloudinary-player.component.html',
  styleUrls: ['./cloudinary-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CloudinaryModule, MediaThumbnailComponent, ObserveVisibilityDirective, CloudImagePipe],
  host: { class: 'x-cloudinary-player' },
})
export class CloudinaryPlayerComponent implements OnChanges {
  private player: any; // To hold the dynamically loaded player
  private cloudinaryUrl: CloudinaryUrl | null = null;
  private videoPlayerConfig: Partial<CloudinaryPlayerConfig> = {
    sourceTypes: ['hls', 'mp4'],
    preload: 'auto',
    loop: true,
    showLogo: false,
    hideContextMenu: true,
    ...VIDEO_MODE_OPTIONS.Autoplay_No_Controls,
  };
  private _url?: string;

  @Input() width?: number;
  @Input() height?: number;
  @Input() aspectRatio?: TVideoAspectRatio;
  @Input() responsiveMediaConfig?: TMediaResponsiveConfig;
  @Input({ required: true })
  set url(url: string | undefined) {
    if (!url) return;
    this._url = url;
    this.cloudinaryUrl = deconstructCloudinaryUrl(url);
  }
  get url() {
    return this._url;
  }

  @Input() set mode(mode: TVideoMode | undefined | null) {
    if (!mode) return;
    this.videoPlayerConfig = {
      ...this.videoPlayerConfig,
      ...VIDEO_MODE_OPTIONS[mode],
    };
  }

  @ViewChild('videoPlayer') playerElement: ElementRef<HTMLVideoElement>;

  @HostBinding('style.aspect-ratio')
  get ar() {
    if (!this.width || !this.height) return;

    return `${this.width} / ${this.height}`;
  }

  get baseTransformation(): CommonTransformationOptions {
    return {
      crop: 'fill',
      ...(this.width && { width: this.width }),
      ...(this.height && { height: this.height }),
      ...(this.aspectRatio && { aspect_ratio: this.aspectRatio }),
      quality: 'auto',
    };
  }

  constructor(public wr: WindowRef) {}

  ngOnChanges(): void {
    this.updatePlayerSource();
  }

  async initPlayer() {
    if (this.wr.isServer) return;

    try {
      if (this.player) return;

      const cloudinary = await import('cloudinary-video-player');
      const { cloud_name, public_id } = this.cloudinaryUrl || {};

      if (!cloud_name) {
        console.warn('Cloudinary cloud name is missing.');
        return;
      }

      if (!public_id) {
        console.warn('Cloudinary public ID is missing.');
        return;
      }

      const _videoConfig: CloudinaryPlayerConfig = {
        cloudName: cloud_name,
        ...this.videoPlayerConfig,
        posterOptions: {
          transformation: { ...this.baseTransformation, start_offset: 0 },
        },
      };

      this.player = cloudinary.videoPlayer(this.playerElement.nativeElement as any, {
        ..._videoConfig,
      });

      this.updatePlayerSource();
    } catch (e) {
      console.error(`Couldn't load Cloudinary player: ${e}`);
    }
  }

  private getSourceTransformations() {
    const sources = this.videoPlayerConfig.sourceTypes || [];

    const sourceTransformation: Record<string, CommonTransformationOptions> = sources.reduce(
      (acc, source) => {
        if (source === 'hls') {
          acc[source] = {
            ...this.baseTransformation,
            streaming_profile: '',
          };
          return acc;
        }

        acc[source] = this.baseTransformation;
        return acc;
      },
      {} as Record<string, CommonTransformationOptions>,
    );

    return { sourceTransformation };
  }

  private updatePlayerSource() {
    if (!this.cloudinaryUrl?.public_id || !this.player) return;

    this.player.source({
      publicId: this.cloudinaryUrl.public_id,
      ...this.getSourceTransformations(),
    });
  }
}
