import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconModule } from '@x/common/icon';
import { LinkModule } from '@x/common/link';
import { IShopNavigationItemContent_Icon } from '@x/content/render/dynamic-components/footer-link-list/footer-link-list.component';

@Component({
  selector: 'x-footer-link',
  standalone: true,
  imports: [FaIconComponent, IconModule, LinkModule, NgIf],
  templateUrl: './footer-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLinkComponent {
  @Input() link: IShopNavigationItemContent_Icon;
}
