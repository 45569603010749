<div class="mat-typography" *ngIf="title || description">
  <h2>{{ title }}</h2>
  <x-markdown-view *ngIf="description" [source]="description"></x-markdown-view>
</div>
<div class="grid">
  <div
    class="action-grid-item"
    *ngFor="let item of items"
    [class]="item.media?.type ?? 'no-media'"
    [ngClass]="{ emphasize: item.emphasize }"
  >
    <a
      [xLink]="item?.url"
      *ngIf="item.media"
      [attr.aria-label]="item?.media?.alternativeText || title"
    >
      <x-content-media
        [media]="item.media"
        [responsiveMediaConfig]="responsiveMediaConfig"
      ></x-content-media>
    </a>
    <div class="link mat-typography">
      <h2>{{ item?.title }}</h2>
      <x-button [content]="{ url: item?.url ?? '', text: item?.text ?? '' }"></x-button>
    </div>
  </div>
</div>
